import { AxiosResponse } from 'axios';
import axiosClient from './baseApi';

export const placeQueryKey: any = {
  getPlacetById: 'getByPlace',
  getAllPlace: 'getAllPlace',
  getFilterPlace: 'getAllPlace',
};

export const getById = async (id: any) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>('/places/one/', id);
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getAll = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>('/places/all');
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getAllFilter = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>('/places/getFilter');
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
