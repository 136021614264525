import { AxiosResponse } from 'axios';

import axiosClient from './baseApi';

export const brandQueryKey: any = {
  getAllBrand: 'getAllBrands',
};

export const getAll = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>('/brand/all');
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
export const getAllFilter = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>('/brand/getFilter');
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
