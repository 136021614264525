import React from 'react';
import Select from 'react-select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPickerSkeleton } from '@mui/x-date-pickers/CalendarPickerSkeleton';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import moment from 'moment';

export const WeekOptionsForDisplay = [
  { value: 0, label: 'Since Last Week' },
  { value: -1, label: 'Since Prior Week' },
  { value: -2, label: 'Since Prior 2 Weeks' },
  { value: 1, label: 'Since Prior Month' },
  { value: 2, label: 'Since Last Date Range' },
];

export const WeekOptions = [
  { value: 0, label: 'This week' },
  { value: -1, label: 'Last week' },
  { value: -2, label: 'Last 2 weeks' },
  { value: 1, label: '1 month' },
  { value: 2, label: 'Custom Date' },
];

export const SHOW_ITEM = [
  {
    value: 10,
    label: '10 rows',
  },
  {
    value: 25,
    label: '25 rows',
  },
  {
    value: 50,
    label: '50 rows',
  },
  {
    value: 100,
    label: '100 rows',
  },
  {
    value: 1000,
    label: 'Show all',
  },
];

export class BasicSelect extends React.Component<
  {},
  { state: any; options: any }
> {
  render() {
    return (
      <Select
        styles={{
          multiValueRemove: (styles) => ({ ...styles, display: 'none' }),
        }}
        defaultValue={WeekOptions[0]}
        options={WeekOptions}
        onChange={(dateOptionValue) => {}}
        placeholder="choose one"
        classNamePrefix="Select"
      />
    );
  }
}

export const BasicSelect2 = () => {
  return (
    <Select
      styles={{
        multiValueRemove: (styles) => ({ ...styles, display: 'none' }),
      }}
      defaultValue={WeekOptions[0]}
      options={WeekOptions}
      onChange={(dateOptionValue) => {}}
      placeholder="choose one"
      classNamePrefix="Select"
    />
  );
};

function getRandomNumber(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

function fakeFetch(date: Date, { signal }: { signal: AbortSignal }) {
  return new Promise<{ daysToHighlight: number[] }>((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = getDaysInMonth(date);
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth),
      );

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException('aborted', 'AbortError'));
    };
  });
}

const initialValue = new Date();

export function ActivityDateTo({
  valueDate,
  setValueDate,
  isLoading,
  setIsLoading,
  valueFilter,
  setValueFilter,
  DataFilter,
}: any) {
  const requestAbortController = React.useRef<AbortController | null>(null);
  // const [isLoading, setIsLoading] = React.useState(false);
  const [, setHighlightedDays] = React.useState([1, 2, 15]);
  // const [value, setValue] = React.useState<Date | null>(initialValue);

  const fetchHighlightedDays = (date: Date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== 'AbortError') {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(valueDate);
    return () => requestAbortController.current?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMonthChange = (date: Date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={valueDate}
        loading={isLoading}
        onChange={(newValue) => {
          setIsLoading(true);
          setValueDate(newValue);
          const momentDataToNow = moment(newValue).format('YYYY-MM-DD');

          setValueFilter((p: any) => {
            return { ...p, date_to: momentDataToNow };
          });
          DataFilter({ ...valueFilter, date_to: momentDataToNow });
        }}
        onMonthChange={handleMonthChange}
        renderInput={(params) => <TextField {...params} />}
        renderLoading={() => <CalendarPickerSkeleton />}
        renderDay={(day, _value, DayComponentProps) => {
          return <PickersDay {...DayComponentProps} />;
        }}
      />
    </LocalizationProvider>
  );
}

export function ActivityDateFrom({
  valueDate,
  setValueDate,
  isLoading,
  setIsLoading,
  valueFilter,
  setValueFilter,
  DataFilter,
}: any) {
  const requestAbortController = React.useRef<AbortController | null>(null);
  // const [isLoading, setIsLoading] = React.useState(false);
  const [, setHighlightedDays] = React.useState([1, 2, 15]);
  // const [value, setValue] = React.useState<Date | null>(initialValue);

  const fetchHighlightedDays = (date: Date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== 'AbortError') {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(valueDate);
    return () => requestAbortController.current?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMonthChange = (date: Date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={valueDate}
        loading={isLoading}
        onChange={(newValue) => {
          setIsLoading(true);
          setValueDate(newValue);
          const momentDataToNow = moment(newValue).format('YYYY-MM-DD');

          setValueFilter((p: any) => {
            return { ...p, date_from: momentDataToNow };
          });
          DataFilter({ ...valueFilter, date_from: momentDataToNow });
        }}
        onMonthChange={handleMonthChange}
        renderInput={(params) => <TextField {...params} />}
        renderLoading={() => <CalendarPickerSkeleton />}
        renderDay={(day, _value, DayComponentProps) => {
          return <PickersDay {...DayComponentProps} />;
        }}
      />
    </LocalizationProvider>
  );
}

export function DashboardDate({
  setDataCalendar,
  dataCalendar,
  setDateRange,
  loadingDashboard,
}: any) {
  const requestAbortController = React.useRef<AbortController | null>(null);

  const [, setHighlightedDays] = React.useState([1, 2, 15]);

  const fetchHighlightedDays = (date: Date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== 'AbortError') {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date: Date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    // setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const onSelcect = (newValue: any) => {
    // setIsLoading(true);
    setDataCalendar(newValue);
    setDateRange && setDateRange(WeekOptions[4]);
    // setFilterValue({
    //   ...filterValue,
    //   from_date: moment(newValue)?.format('YYYY-MM-DD'),
    // });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={dataCalendar}
        onChange={(newValue) => {
          onSelcect(newValue);
        }}
        loading={loadingDashboard ? true : undefined}
        disabled={loadingDashboard ? true : undefined}
        onMonthChange={handleMonthChange}
        renderInput={(params) => <TextField {...params} />}
        renderLoading={() => <CalendarPickerSkeleton />}
        renderDay={(day, _value, DayComponentProps) => {
          // const isSelected =
          //   !DayComponentProps.outsideCurrentMonth &&
          //   highlightedDays.indexOf(day.getDate()) > 0;

          return <PickersDay {...DayComponentProps} />;
        }}
      />
    </LocalizationProvider>
  );
}
