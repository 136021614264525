import { AxiosResponse } from 'axios';
import axiosClient from './baseApi';

export const productQueryKey: any = {
  getProductById: 'getByProduct',
  getAllProduct: 'getAllProduct',
  getFilterProduct: 'getFilterProduct',
};

export const getById = async (id: any) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>('/products/one/', id);
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getAll = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>('/products/all');
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getAllReferenceData = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      '/products/getFilter',
    );
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
