import { useState } from 'react';
import Select from 'react-select';
import { useQuery } from 'react-query';
import {
  getAllFilter as getAllPlace,
  placeQueryKey,
} from '../../services/apiPlace';

interface driverProp {
  value: any | null;
  onChange: (value: any | null) => void;
  loading: boolean;
}

const SelectStoreFilter = ({ loading, value, onChange }: driverProp) => {
  const [isLoading, setIsLoading] = useState(false);

  const { data, status } = useQuery<any>(
    [placeQueryKey?.getAllPlace],
    () => {
      setIsLoading(true);
      return getAllPlace();
    },
    {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    },
  );

  const options: any =
    data?.data?.map((place: any) => ({
      label: place?.name,
      value: place?.place_id,
    })) ?? [];

  return (
    <div className="select-user">
      <Select
        isDisabled={loading}
        className="select-filter-items custom-inner w-100"
        classNamePrefix="select"
        placeholder="Select"
        options={options}
        key={value}
        value={value}
        onChange={onChange}
        isClearable
        isLoading={isLoading || status === 'loading'}
      />
      {/*  {(isLoading || status === 'loading') && (
        <div className="select-user-loading">Loading...</div>
      )} */}
    </div>
  );
};

export default SelectStoreFilter;
