import styles from './PageHeader.module.scss';
import './PageHeader.css';
import { Link } from 'react-router-dom';

const PageHeader = (props: any) => {
  return (
    <div className={styles.PageHeader}>
      <div className="page-header mt-0 mb-1">
        <h1 className="page-title">{props.titles}</h1>
        <div>
          <ol className="breadcrumb">
            {props.items.map((value: any, index: any) => {
              return (
                <li key={index} className="breadcrumb-item">
                  <Link to="#">{value}</Link>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
