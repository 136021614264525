export const DEFAULT_BASE_URL = '';
export const DEFAULT_BASE_API = 'http://localhost:3000/api/v1';
export const DEFAULT_BASE_API_LOCAL = 'http://localhost:3000/api/v1';
export const TOKEN = 'retailer';
export const INPUT_MIN_LENGTH = 4;
export const INPUT_MAX_LENGTH = 120;
export const MIN_FILE_SIZE_BYTES = 153600;
export const MAX_FILE_SIZE_BYTES = 8192000;

export const STATUS_LOADING = 'loading';
export const STATUS_ERROR = 'error';

// Columns: Product
export const COLUMNS_PRODUCT = [
  {
    Header: 'IMAGE',
    accessor: 'IMAGE',
    className: 'text-center ',
    disableSortBy: true,
  },
  {
    Header: 'PRODUCT CODE',
    accessor: 'PRODUCT_CODE',
    className: 'text-center ',
  },
  {
    Header: 'UPC',
    accessor: 'UPC',
    className: 'text-center ',
  },
  {
    Header: 'PRODUCT NAME',
    accessor: 'PRODUCT_NAME',
    className: 'text-center ',
  },
  {
    Header: 'STATUS',
    accessor: 'STATUS',
    className: 'text-center ',
  },
  {
    Header: 'VIEW',
    accessor: 'VIEW',
    className: 'text-center ',
    disableSortBy: true,
  },
];

export const COLUMNS_PRICE_PROMOTION = [
  {
    Header: 'NAME',
    accessor: 'NAME',
    className: 'text-center',
  },
  {
    Header: 'FROM',
    accessor: 'FROM',
    className: 'text-center',
  },
  {
    Header: 'BASE PRICE',
    accessor: 'BASE_PRICE',
    className: 'text-center base-price-col',
    cellClassName: 'text-center',
  },
  {
    Header: 'PROMO',
    accessor: 'PROMOTION_PRICE',
    className: 'text-center promo-price-col',
    cellClassName: 'text-center',
  },
  {
    Header: 'PROMO FROM',
    accessor: 'PROMOTION_FROM',
    className: 'text-center ',
  },
  {
    Header: 'PROMO TO',
    accessor: 'PROMOTION_TO',
    className: 'text-center ',
  },
  {
    Header: 'STATUS',
    accessor: 'STATUS',
    className: 'text-center ',
  },
];

export const COLUMNS_PRICE = [
  {
    Header: 'NAME',
    accessor: 'NAME',
    className: 'text-center',
  },
  {
    Header: 'FROM',
    accessor: 'FROM',
    className: 'text-center',
  },
  {
    Header: 'BASE PRICE',
    accessor: 'BASE_PRICE',
    className: 'text-center base-price-col',
  },
  {
    Header: 'STATUS',
    accessor: 'STATUS',
    className: 'text-center',
  },
];

export const COLUMNS_PRICE_STORE_PROMOTION = [
  {
    Header: 'NAME',
    accessor: 'NAME',
    className: 'text-center',
  },
  {
    Header: 'FROM',
    accessor: 'FROM',
    className: 'text-center',
  },
  {
    Header: 'BASE PRICE',
    accessor: 'BASE_PRICE',
    className: 'text-center base-price-col',
    cellClassName: 'text-center',
  },
  {
    Header: 'PROMOTION',
    accessor: 'PROMOTION_PRICE',
    className: 'text-center promo-price-col',
    cellClassName: 'text-right',
  },
  {
    Header: 'PROMOTION FROM',
    accessor: 'PROMOTION_FROM',
    className: 'text-center',
  },
  {
    Header: 'PROMOTION TO',
    accessor: 'PROMOTION_TO',
    className: 'text-center',
  },
  {
    Header: 'STATUS',
    accessor: 'STATUS',
    className: 'text-center',
  },
];

export const COLUMNS_PRICE_STORE = [
  {
    Header: 'NAME',
    accessor: 'NAME',
    className: 'text-center',
  },
  {
    Header: 'FROM',
    accessor: 'FROM',
    className: 'text-center',
  },
  {
    Header: 'BASE PRICE',
    accessor: 'BASE_PRICE',
    className: 'text-center base-price-col',
    cellClassName: 'text-center',
  },
  {
    Header: 'PROMOTION',
    accessor: 'PROMOTION_PRICE',
    className: 'text-center promo-price-col',
    cellClassName: 'text-right',
  },
];

// Columns: Activity
export const COLUMNS_ACTIVITY = [
  {
    Header: 'ACTIVITY',
    accessor: 'ACTIVITY',
    disableSortBy: true,
    className: 'text-left wr-18',
    expandedContent: (rowData: any) => rowData,
  },
  {
    Header: 'RETAILER',
    accessor: 'RETAILER',
    className: 'text-left w-17',
    disableSortBy: true,
  },
  {
    Header: 'CHECK IN/OUT',
    accessor: 'CHECK_IN_OUT',
    className: 'text-left wr-17',
    disableSortBy: true,
  },
  {
    Header: 'GMV',
    accessor: 'GMV',
    className: 'text-left wr-8',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'Sku',
    className: 'wr-20',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'before',
    className: 'wr-20',
    disableSortBy: true,
  },
];

export const COLUMNS_ACTIVITY_PRODUCT = [
  {
    Header: 'Product Name',
    accessor: 'PRODUCT_NAME',
    className: 'wr-12',
  },
  {
    Header: 'Stock',
    accessor: 'STOCK',
    className: 'wr-8',
  },
  {
    Header: 'Credits',
    accessor: 'CREDITS',
    className: 'wr-8',
  },
  {
    Header: 'Delivery',
    accessor: 'DELIVERY',
    className: 'wr-11h',
  },
  {
    Header: 'GMV',
    accessor: 'GMV',
    className: 'wr-6',
  },
  {
    Header: 'UPC',
    accessor: 'SKU',
    className: 'wr-37',
  },
];
