import { numberWithCommas } from '../components/Global/converse-comma';

const capitalizeFirstLetter = (string: string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

const moneyFormat = (price: any, hasPrefix: boolean) => {
  const dollar = hasPrefix ? '$' : '';
  if (parseFloat(price) < 0) {
    const newPrice = price?.toString()?.replace('-', '');
    return `-${dollar}${numberWithCommas(newPrice)}`;
  } else {
    return `${dollar}${numberWithCommas(price)}`;
  }
};

export { capitalizeFirstLetter, moneyFormat };
