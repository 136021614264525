import { Navigate, useLocation } from 'react-router-dom';
import { useAuthen } from '../authen/AuthenProvider';

const CheckUserPending = () => {
  const { userInfo } = useAuthen();
  const location = useLocation();

  const statusBrand = userInfo?.banner?.status;

  if (statusBrand === 0) {
    if (
      location.pathname === '/dashboard/' ||
      location.pathname === '/activity/' ||
      location.pathname === '/reports/field-order-report/' ||
      location.pathname === '/reports/stock-audit-report/'
    ) {
      return <Navigate to="/no-permission" />;
    }
    return <Navigate to="/profile" />;
  }
  return <></>;
};
export default CheckUserPending;
